import React from 'react';

const GameBackground = ({ level, coralHealth }) => {
  const backgroundStyleBase = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    transition: 'opacity 1s ease-in-out',
  };

  const sickCoralStyle = {
    ...backgroundStyleBase,
    backgroundImage: 'url("/image/ocean-coral-image-sick.png")',
    zIndex: -2,
  };

  const healthyCoralStyle = {
    ...backgroundStyleBase,
    backgroundImage: 'url("/image/ocean-coral-image.png")',
    zIndex: -1,
    opacity: level === 1 ? 1 : coralHealth / 100,
  };

  return (
    <>
      <div style={sickCoralStyle}></div>
      <div style={healthyCoralStyle}></div>
    </>
  );
};

export default GameBackground;