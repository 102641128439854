import React, { useState, useEffect, useRef } from 'react';
import GameBackground from './GameBackground';

const SPAWN_COUNT = 40;
const CLOWNFISH_COUNT = 4;
const FLEE_DISTANCE = 20;
const NORMAL_SPEED = 0.2;
const FLEE_SPEED = 2.6;
const LEVEL_1_SCORE = 20;
const INITIAL_CORAL_INVENTORY = 20;

const Larvalbot = ({ x, y, direction, coralInventory }) => (
  <div style={{
    position: 'absolute',
    left: `${x}%`,
    top: `${y}%`,
    width: '10%',
    height: '10%',
    backgroundImage: 'url("/image/bot.png")',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    transform: direction === 'left' ? 'scaleX(-1)' : 'scaleX(1)',
  }}>
    {coralInventory > 0 && (
      <div style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: '30%',
        height: '30%',
        backgroundColor: 'rgba(255, 100, 100, 0.4)',
        borderRadius: '50%',
        transform: 'translate(-50%, -50%)',
      }}/>
    )}
  </div>
);

const CoralSpawn = ({ x, y, level }) => (
  <div style={{
    position: 'absolute',
    left: `${x}%`,
    top: `${y}%`,
    width: '1.9%',
    height: '1.3%',
    borderRadius: '60%',
    backgroundColor: level === 1 ? 'rgba(255, 100, 100, 0.6)' : 'rgba(255, 100, 100, 0.6)',
  }}></div>
);

const Clownfish = ({ x, y, direction, frame }) => {
  const randomSize = useRef(Math.random() * 3 + 2).current;
  return (
    <div style={{
      position: 'absolute',
      left: `${x}%`,
      top: `${y}%`,
      width: `${randomSize}%`,
      height: `${randomSize}%`,
      backgroundImage: 'url("/image/clown.png")',
      backgroundSize: 'cover',
      transform: direction === 'right' ? 'scaleX(-1)' : 'scaleX(1)',
      transition: 'left 0.05s, top 0.05s',
    }}></div>
  );
};



const Bubble = ({ x, y }) => {
  const randomOpacity = Math.random() * 0.5 + 0.3;
  return (
    <div
      style={{
        position: 'absolute',
        left: `${x}%`,
        top: `${y}%`,
        width: '18%',
        height: '18%',
        backgroundImage: 'url("/image/bubble.png")',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        animation: 'float-and-fade 4s ease-out forwards',
        opacity: randomOpacity,
      }}
    />
  );
};

// New component for the win pop-up
const WinPopup = ({ onRestart }) => (
  <div style={{
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  }}>
    <div style={{
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '10px',
      textAlign: 'center',
    }}>
      <h2>You Win!</h2>
      <p>Congratulations! You've restored the coral reef!</p>
      <p><a href="https://www.qut.edu.au/engage/giving/support-research/great-barrier-reef">Find out more about Larvalbot!</a></p>
      <button onClick={onRestart} style={{
        marginTop: '10px',
        padding: '10px 20px',
        fontSize: '16px',
        cursor: 'pointer',
      }}>
        Play Again
      </button>
    </div>
  </div>
);

const Game = () => {
  const [level, setLevel] = useState(1);
  const [larvalbot, setLarvalbot] = useState({ x: 50, y: 50, direction: 'right', coralInventory: 0 });
  const [coralSpawns, setCoralSpawns] = useState([]);
  const [clownfish, setClownfish] = useState([]);
  const [score, setScore] = useState(0);
  const [bubbles, setBubbles] = useState([]);
  const [coralHealth, setCoralHealth] = useState(0);
  const movementAudioRef = useRef(new Audio('/sound/bubbles_in_the_ocean.mp3'));
  const suckingAudioRef = useRef(new Audio('/sound/sucking.mp3'));
  const [keysPressed, setKeysPressed] = useState({});
  const lastMoveRef = useRef({ x: 50, y: 50 });
  const isMovingRef = useRef(false);
  const lastBubbleTimeRef = useRef(0);
  const [showWinPopup, setShowWinPopup] = useState(false);

  useEffect(() => {
    resetLevel();
  }, [level]);

  const resetLevel = () => {
    setLarvalbot(prev => ({ ...prev, x: 50, y: 50, direction: 'right', coralInventory: level === 2 ? INITIAL_CORAL_INVENTORY : 0 }));
    setCoralSpawns(level === 1 ? Array(SPAWN_COUNT).fill().map(() => ({
      x: Math.random() * 98,
      y: Math.random() * 98
    })) : []);
    setClownfish(Array(CLOWNFISH_COUNT).fill().map(() => ({
      x: Math.random() * 95,
      y: Math.random() * 95,
      direction: Math.random() < 0.5 ? 'right' : 'left',
      speed: Math.random() * 0.2 + 0.1
    })));
    setScore(0);
    setCoralHealth(0);
  };

  useEffect(() => {
    if (level === 2 && coralHealth >= 100) {
      setShowWinPopup(true);
    }
  }, [level, coralHealth]);

  const handleRestart = () => {
    setShowWinPopup(false);
    setLevel(1);
    resetLevel();
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
        setKeysPressed(prev => ({ ...prev, [e.key]: true }));
        isMovingRef.current = true;
      } else if (e.key === ' ' && level === 2) {
          setLarvalbot(prev => {
          if (prev.coralInventory > 0) {
            const newCoralInventory = prev.coralInventory - 1;
            setCoralHealth(prevHealth => Math.min(100, prevHealth + 5));
            setScore(prevScore => prevScore + 1);
            suckingAudioRef.current.play();
            setCoralSpawns(prevSpawns => [...prevSpawns, { x: prev.x, y: prev.y }]);
            return { ...prev, coralInventory: newCoralInventory };
          }
          return prev;
        });
      }
    }; 

    const handleKeyUp = (e) => {
      if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
        setKeysPressed(prev => ({ ...prev, [e.key]: false }));
        if (!Object.values(keysPressed).some(value => value)) {
          isMovingRef.current = false;
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [level, keysPressed]);

  useEffect(() => {
    const moveInterval = setInterval(() => {
      setLarvalbot(prev => {
        const speed = 2.1;
        let newX = prev.x;
        let newY = prev.y;
        let newDirection = prev.direction;

        if (keysPressed.ArrowUp) newY = Math.max(0, prev.y - speed);
        if (keysPressed.ArrowDown) newY = Math.min(90, prev.y + speed);
        if (keysPressed.ArrowLeft) {
          newX = Math.max(0, prev.x - speed);
          newDirection = 'left';
        }
        if (keysPressed.ArrowRight) {
          newX = Math.min(90, prev.x + speed);
          newDirection = 'right';
        }

        if (newX !== prev.x || newY !== prev.y) {
          if (!isMovingRef.current) {
            isMovingRef.current = true;
            movementAudioRef.current.play().catch(e => console.error("Error playing audio:", e));
          }

          const currentTime = Date.now();
          if (currentTime - lastBubbleTimeRef.current > 500) { // Create bubbles every 500ms
            createBubbles(newX, newY);
            lastBubbleTimeRef.current = currentTime;
          }
        } else {
          isMovingRef.current = false;
        }

        return { ...prev, x: newX, y: newY, direction: newDirection };
      });
    }, 36); // 60 FPS

    return () => clearInterval(moveInterval);
  }, [keysPressed]);


  const createBubbles = (x, y) => {
    const newBubbles = Array(1).fill().map(() => ({
      id: Math.random(),
      x: x + Math.random() * 0 - 5,
      y: y + Math.random() * 4 - 15,
    }));
    setBubbles(prev => [...prev, ...newBubbles]);
  
    // Remove bubbles after animation
    setTimeout(() => {
      setBubbles(prev => prev.filter(bubble => !newBubbles.includes(bubble)));
    }, 3000); // 3000ms matches the animation duration
  };

  useEffect(() => {
    const checkCollisions = () => {
      if (level === 1) {
        const updatedSpawns = coralSpawns.filter(spawn => {
          const distance = Math.sqrt(
            Math.pow(larvalbot.x - spawn.x, 2) + Math.pow(larvalbot.y - spawn.y, 2)
          );
          if (distance < 5) {
            suckingAudioRef.current.play();
            setScore(prev => prev + 1);
            return false;
          }
          return true;
        });

        if (updatedSpawns.length < coralSpawns.length) {
          while (updatedSpawns.length < SPAWN_COUNT) {
            updatedSpawns.push({
              x: Math.random() * 98,
              y: Math.random() * 98
            });
          }
          setCoralSpawns(updatedSpawns);
        }
      }
    };

    checkCollisions();

    if (level === 1 && score >= LEVEL_1_SCORE) {
      setLevel(2);
    }
  }, [larvalbot, coralSpawns, level, score]);

  useEffect(() => {
    const moveClownfish = () => {
      setClownfish(prevFish => prevFish.map(fish => {
        let newX = fish.x;
        let newY = fish.y;
        let newDirection = fish.direction;
        let fleeSpeed = NORMAL_SPEED;

        // Calculate distance to Larvalbot
        const distanceToLarvalbot = Math.sqrt(
          Math.pow(larvalbot.x - fish.x, 2) + Math.pow(larvalbot.y - fish.y, 2)
        );

        if (distanceToLarvalbot < FLEE_DISTANCE) {
          // Fish is close to Larvalbot, flee!
          fleeSpeed = FLEE_SPEED;
          const angleToLarvalbot = Math.atan2(larvalbot.y - fish.y, larvalbot.x - fish.x);
          newX = fish.x - Math.cos(angleToLarvalbot) * fleeSpeed;
          newY = fish.y - Math.sin(angleToLarvalbot) * fleeSpeed;
          newDirection = newX > fish.x ? 'right' : 'left';
        } else {
          // Normal movement
          newX = fish.x + (fish.direction === 'right' ? NORMAL_SPEED : -NORMAL_SPEED);
          if (newX <= 0 || newX >= 95) {
            newDirection = newDirection === 'right' ? 'left' : 'right';
            newX = fish.x;
          }
          newY = fish.y + (Math.random() - 0.5) * 0.3;
        }

        return {
          ...fish,
          x: Math.max(0, Math.min(95, newX)),
          y: Math.max(0, Math.min(95, newY)),
          direction: newDirection,
        };
      }));
    };
   
    const interval = setInterval(moveClownfish, 15);
    return () => clearInterval(interval);
  }, [larvalbot]);


  useEffect(() => {
    if (level === 2) {
      const fishCount = Math.floor(CLOWNFISH_COUNT + (coralHealth / 10));
      setClownfish(prevFish => {
        if (prevFish.length < fishCount) {
          return [
            ...prevFish,
            ...Array(fishCount - prevFish.length).fill().map(() => ({
              x: Math.random() * 95,
              y: Math.random() * 95,
              direction: Math.random() < 0.5 ? 'right' : 'left',
              speed: Math.random() * 0.2 + 0.1
            }))
          ];
        }
        return prevFish;
      });
    }
  }, [coralHealth, level]);

  return (
    <div style={{ position: 'relative', width: '100vw', height: '100vh', overflow: 'hidden' }}>
      <GameBackground level={level} coralHealth={coralHealth} />

    <style>
    {`
      @keyframes float-and-fade {
        0% {
          transform: translateY(0);
          opacity: 0.7;
        }
        100% {
          transform: translateY(-100px);
          opacity: 0;
        }
      }
    `}
  </style>

      <Larvalbot x={larvalbot.x} y={larvalbot.y} direction={larvalbot.direction} coralInventory={larvalbot.coralInventory} />
      {coralSpawns.map((spawn, index) => (
        <CoralSpawn key={index} x={spawn.x} y={spawn.y} level={level} />
      ))}
      {clownfish.map((fish, index) => (
        <Clownfish key={index} x={fish.x} y={fish.y} direction={fish.direction} />
      ))}
      {bubbles.map(bubble => (
        <Bubble key={bubble.id} x={bubble.x} y={bubble.y} />
      ))}
      <div style={{ position: 'absolute', top: '2%', left: '2%', color: 'white', fontSize: '24px', fontWeight: 'bold' }}>
        {level === 1 ? `Score: ${score}` : `Coral Health: ${coralHealth}%`}
      </div>
      {level === 2 && (
        <div style={{ position: 'absolute', top: '7%', left: '2%', color: 'white', fontSize: '18px' }}>
          Coral Inventory: {larvalbot.coralInventory}
        </div>
      )}
      {showWinPopup && <WinPopup onRestart={handleRestart} />}

    </div>
  );
};

export default Game;

